export default Object.freeze({
      
    // APP_URL: 'https://eid.gea.gov.sa',
    APP_URL: 'https://gea-eid-p2.stocker.team',
    APP_LANG: 'ar', //ar, en
    EID_EVENT: "https://enjoy.sa/",
    EID_EVENT_EN: "https://enjoy.sa/en",
    EID_EVENT_QUERY_PARAM: "search?eventCategoriesIds=212800&page=1",
    BOOKLET:"./files/Eid-Events-Booklet.pdf",
    GREETING_CARD:"./files/Eid-Greeting-Cards.zip",
    TOOLKIT:"./files/Eid-Al-Fitr24-Toolkit.zip",
    HOUSE_DECORATION_HORIZANTAL:"./files/Eid-House-Decoration-H.zip",
    COMPANY_DECORATION_HORIZANTAL:"./files/Eid-Companies-Decoration-H.zip",
    GA:"G-QY2LL1BM4R",
    PHP_SERVER: "/api/"
});